import React from "react"
import { Link } from "gatsby"

export const PostDetail = ({ post }) => {
  return (
    <article className="post content-container">
      <figure>
        {post.frontmatter.frame && (
          <img
            src={post.frontmatter.frame.publicURL}
            alt=""
            className="frame"
            width={post.frontmatter.imageWidth}
            height={post.frontmatter.imageHeight}
          />
        )}
        <img
          src={post.frontmatter.image.publicURL}
          alt={post.frontmatter.imageAlt}
          width={post.frontmatter.imageWidth}
          height={post.frontmatter.imageHeight}
        />
      </figure>
      <div className="post-content">
        <h1 id="post-title">
          <span>{post.frontmatter.title}</span>
          {post.frontmatter.title2 && (
            <React.Fragment>
              <small> & </small>
              <span>{post.frontmatter.title2}</span>
            </React.Fragment>
          )}
        </h1>
        <h2>
          {post.frontmatter.role && (
            <React.Fragment>
              {post.frontmatter.role}
            </React.Fragment>
          )}
          {post.frontmatter.company && (
            <React.Fragment>
              <span>, </span><a href={post.frontmatter.companyLink}>{post.frontmatter.company}</a>
            </React.Fragment>
          )}
          {post.frontmatter.role2 && (
            <React.Fragment>
              <br/>
              <span>{post.frontmatter.role2}</span>
            </React.Fragment>
          )}
          {post.frontmatter.company2 && (
            <React.Fragment>
              <span>, </span><a href={post.frontmatter.company2Link}>{post.frontmatter.company2}</a>
            </React.Fragment>
          )}
        </h2>
        <div
          className="content-container"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </article>
  )
}

export const PostItem = ({ post }) => {

  return (
    <figure
      className={`portrait ${post.frontmatter.slug}`}
    >
      <Link
        to={`/haveyoumet/${post.frontmatter.slug}`}
        className="post-link"
        aria-label={`Meet ${post.frontmatter.title}`}
      >
        {post.frontmatter.frame && (
          <img
            src={post.frontmatter.frame.publicURL}
            alt=""
            className="frame"
            width={post.frontmatter.imageWidth}
            height={post.frontmatter.imageHeight}
          />
        )}
        <img
          loading="lazy"
          src={post.frontmatter.image.publicURL}
          alt={post.frontmatter.imageAlt}
          width={post.frontmatter.imageWidth}
          height={post.frontmatter.imageHeight}
        />
        <span className="name-plaque">
          {post.frontmatter.title}
          {post.frontmatter.title2 && (
            <React.Fragment>
              <small> & </small>
              <span>{post.frontmatter.title2}</span>
            </React.Fragment>
          )}
        </span>
      </Link>
    </figure>
  )
}

export const PostItems = ({ posts }) => {
  return (
    <React.Fragment>
      {posts.edges.map((post) => (
        <PostItem
          key={post.node.frontmatter.slug}
          post={post.node}
        />
      ))}
    </React.Fragment>
  )
}
