import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PostItems } from "../components/post"
import FYW from "../assets/images/find-your-weirdos.svg"

const IndexPage = () => {
  const { posts } = useStaticQuery(
    graphql`
      fragment PostFields on MarkdownRemark {
        frontmatter {
          title
          title2
          role
          company
          companyLink
          role2
          company2
          company2Link
          description
          slug
          frame {
            publicURL
          }
          image {
            publicURL
          }
          socialImage {
            publicURL
          }
          imageAlt
          imageWidth
          imageHeight
        }
      }
      query {
        posts: allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 1000
        ) {
          edges {
            node {
              ...PostFields
            }
          }
        }
      }
    `
  )

  return (
    <Layout page="home">
      <SEO title="Meet Our Weirdos" />

      <div className="content-container">
        <section className="gallery-statement">
          <h1>
            Meet Our Weirdos
          </h1>
          <p>We’re <a href="https://andyet.com/">&yet</a> and lately we’ve been fascinated by weirdos. You know, those people who are passionate about the same weird things you are. The people who just <strong><em>get</em></strong> you.</p>
          <p>For businesses, <a href="https://find.yourweirdos.com/">finding your weirdos</a> can be both <strong>practical</strong> <em>and</em> <strong>fulfilling</strong>. It’s a smart approach to connecting with your community and customers in a meaningful way.</p>
          <p>So we want you to meet <strong><em>our</em></strong> weirdos: some amazing folks who share our values and are taking a people-first approach to business. They’re sharing what they geek out about and how those fascinations show up in their work.</p>
          </section>
        <PostItems posts={posts} />
        <a 
          href="https://find.yourweirdos.com"
          className="callout-fyw"
          target="_blank">
          <img
              src={FYW}
              alt="Find Your Weirdos"
            />
        </a>
      </div>

    </Layout>
  )
}

export default IndexPage
